import {get} from '../util/request.js';
import {showErrorTip} from '../util/index.js'
// 获取课程体系列表
const getList = async () => {
  const res = await get({
    url:'/api/MyProductAPI',
  });
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}

export {
  getList
}