import {Tag,Select,Option,Input,Button,Pagination } from 'element-ui';
import {getList} from '../api/product';
import {showErrorTip} from '../util/index.js';
export default {
  name:'index',
  components:{
    elTag:Tag,
    elSelect:Select,
    elOption:Option,
    elInput:Input,
    elButton:Button,
    elPagination:Pagination,
  },
  data(){
    return {
      list:[],
    }
  },
  async mounted(){
    const res = await getList();
    if(res.error !== null){
      showErrorTip(res.data);
    }else{
      this.list = res.data;
    }
  },
  methods:{
    pageTodetail(item){
      if(item.enrolled === true){
        this.$router.push(`/course/${item.id}`)
      }
    }
  }
}